import { default as archiveAVaclDYvRRMeta } from "/home/node/pages/auth/archive.vue?macro=true";
import { default as _91id_93ox4rhfm5CWMeta } from "/home/node/pages/auth/contacts/[id].vue?macro=true";
import { default as add22eYaLORBXMeta } from "/home/node/pages/auth/contacts/add.vue?macro=true";
import { default as _91id_93aoIw0lhvF5Meta } from "/home/node/pages/auth/contacts/corporations/[id].vue?macro=true";
import { default as add91YYk7FAK7Meta } from "/home/node/pages/auth/contacts/corporations/add.vue?macro=true";
import { default as _91id_93c07gn6ZcWeMeta } from "/home/node/pages/auth/contacts/corporations/delete/[id].vue?macro=true";
import { default as indexq4MTAFMiRbMeta } from "/home/node/pages/auth/contacts/corporations/index.vue?macro=true";
import { default as _91id_93uIWCHdeZBVMeta } from "/home/node/pages/auth/contacts/delete/[id].vue?macro=true";
import { default as indexNPdaGTZSHSMeta } from "/home/node/pages/auth/contacts/index.vue?macro=true";
import { default as indexWYUL1ULkVVMeta } from "/home/node/pages/auth/index.vue?macro=true";
import { default as contactsxwaRNgezPoMeta } from "/home/node/pages/auth/projects/[id]/contacts.vue?macro=true";
import { default as deletehCQZPpfa6qMeta } from "/home/node/pages/auth/projects/[id]/delete.vue?macro=true";
import { default as editpAg2Udir5eMeta } from "/home/node/pages/auth/projects/[id]/edit.vue?macro=true";
import { default as index8eYq0AEQ5KMeta } from "/home/node/pages/auth/projects/[id]/index.vue?macro=true";
import { default as addvkWOKccVrHMeta } from "/home/node/pages/auth/projects/add.vue?macro=true";
import { default as deletev6E5zcmO9TMeta } from "/home/node/pages/auth/schedule/[id]/delete.vue?macro=true";
import { default as editIprmZcXUnEMeta } from "/home/node/pages/auth/schedule/[id]/edit.vue?macro=true";
import { default as indexoGDsrktn4kMeta } from "/home/node/pages/auth/schedule/[id]/index.vue?macro=true";
import { default as deletevF3kL7qxc4Meta } from "/home/node/pages/auth/schedule/[id]/sections/[sectionId]/delete.vue?macro=true";
import { default as edit23CHqE4NRLMeta } from "/home/node/pages/auth/schedule/[id]/sections/[sectionId]/edit.vue?macro=true";
import { default as addHWCPUy2RDsMeta } from "/home/node/pages/auth/schedule/[id]/sections/add.vue?macro=true";
import { default as _91projectId_93NClhOJlSbQMeta } from "/home/node/pages/auth/schedule/add/[projectId].vue?macro=true";
import { default as _91id_93cPrmMQblKwMeta } from "/home/node/pages/auth/users/[id].vue?macro=true";
import { default as addCyLRmPYConMeta } from "/home/node/pages/auth/users/add.vue?macro=true";
import { default as _91id_93G4exPwrbXZMeta } from "/home/node/pages/auth/users/delete/[id].vue?macro=true";
import { default as indexCErk6PWsFRMeta } from "/home/node/pages/auth/users/index.vue?macro=true";
import { default as profilefOmF7wZafgMeta } from "/home/node/pages/auth/users/profile.vue?macro=true";
import { default as indexjBvbyAgHxEMeta } from "/home/node/pages/index.vue?macro=true";
import { default as loginuGvLEzBnTGMeta } from "/home/node/pages/login.vue?macro=true";
import { default as _91id_93eoxSBF05LsMeta } from "/home/node/pages/snapshot/schedule/[id].vue?macro=true";
export default [
  {
    name: "auth-archive",
    path: "/auth/archive",
    component: () => import("/home/node/pages/auth/archive.vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-id",
    path: "/auth/contacts/:id()",
    component: () => import("/home/node/pages/auth/contacts/[id].vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-add",
    path: "/auth/contacts/add",
    component: () => import("/home/node/pages/auth/contacts/add.vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-corporations-id",
    path: "/auth/contacts/corporations/:id()",
    component: () => import("/home/node/pages/auth/contacts/corporations/[id].vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-corporations-add",
    path: "/auth/contacts/corporations/add",
    component: () => import("/home/node/pages/auth/contacts/corporations/add.vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-corporations-delete-id",
    path: "/auth/contacts/corporations/delete/:id()",
    component: () => import("/home/node/pages/auth/contacts/corporations/delete/[id].vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-corporations",
    path: "/auth/contacts/corporations",
    component: () => import("/home/node/pages/auth/contacts/corporations/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-delete-id",
    path: "/auth/contacts/delete/:id()",
    component: () => import("/home/node/pages/auth/contacts/delete/[id].vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts",
    path: "/auth/contacts",
    component: () => import("/home/node/pages/auth/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    component: () => import("/home/node/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-projects-id-contacts",
    path: "/auth/projects/:id()/contacts",
    component: () => import("/home/node/pages/auth/projects/[id]/contacts.vue").then(m => m.default || m)
  },
  {
    name: "auth-projects-id-delete",
    path: "/auth/projects/:id()/delete",
    component: () => import("/home/node/pages/auth/projects/[id]/delete.vue").then(m => m.default || m)
  },
  {
    name: "auth-projects-id-edit",
    path: "/auth/projects/:id()/edit",
    component: () => import("/home/node/pages/auth/projects/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "auth-projects-id",
    path: "/auth/projects/:id()",
    component: () => import("/home/node/pages/auth/projects/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-projects-add",
    path: "/auth/projects/add",
    component: () => import("/home/node/pages/auth/projects/add.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-id-delete",
    path: "/auth/schedule/:id()/delete",
    component: () => import("/home/node/pages/auth/schedule/[id]/delete.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-id-edit",
    path: "/auth/schedule/:id()/edit",
    component: () => import("/home/node/pages/auth/schedule/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-id",
    path: "/auth/schedule/:id()",
    component: () => import("/home/node/pages/auth/schedule/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-id-sections-sectionId-delete",
    path: "/auth/schedule/:id()/sections/:sectionId()/delete",
    component: () => import("/home/node/pages/auth/schedule/[id]/sections/[sectionId]/delete.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-id-sections-sectionId-edit",
    path: "/auth/schedule/:id()/sections/:sectionId()/edit",
    component: () => import("/home/node/pages/auth/schedule/[id]/sections/[sectionId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-id-sections-add",
    path: "/auth/schedule/:id()/sections/add",
    component: () => import("/home/node/pages/auth/schedule/[id]/sections/add.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-add-projectId",
    path: "/auth/schedule/add/:projectId()",
    component: () => import("/home/node/pages/auth/schedule/add/[projectId].vue").then(m => m.default || m)
  },
  {
    name: "auth-users-id",
    path: "/auth/users/:id()",
    component: () => import("/home/node/pages/auth/users/[id].vue").then(m => m.default || m)
  },
  {
    name: "auth-users-add",
    path: "/auth/users/add",
    component: () => import("/home/node/pages/auth/users/add.vue").then(m => m.default || m)
  },
  {
    name: "auth-users-delete-id",
    path: "/auth/users/delete/:id()",
    component: () => import("/home/node/pages/auth/users/delete/[id].vue").then(m => m.default || m)
  },
  {
    name: "auth-users",
    path: "/auth/users",
    component: () => import("/home/node/pages/auth/users/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-users-profile",
    path: "/auth/users/profile",
    component: () => import("/home/node/pages/auth/users/profile.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/node/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/node/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "snapshot-schedule-id",
    path: "/snapshot/schedule/:id()",
    component: () => import("/home/node/pages/snapshot/schedule/[id].vue").then(m => m.default || m)
  }
]